.Select {
    position: relative;
    z-index: 10;
}

/* Select__support */
.Select > .Input > .Input__support > .Select__support {
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;
    transform: rotate(0deg);
    transition: transform ease .25s;
    will-change: transform;

}

.Select .Input .Input__support {
    display: grid;
    place-items: center;
}

.Select--focused > .Input > .Input__support > .Select__support {
    transform: rotate(-180deg);
}

.Select > .Select__container {
    position: absolute;
    display: none;
    background: white;
    top: 80%;
    left: 0;
    right: 0;
    padding: 4px;
    max-height: 164px;
    overflow-x: hidden;
    overflow-y: auto;
    box-shadow: 0 15px 25px #E6D8DE;
    border-radius: 4px;
    z-index: 200;
}


/* Select focus */
.Select--focused {

}

.Select--focused > .Select__container {
    display: block;
    animation: select-appearance ease-in .15s;

}

/* ScrollBar */
.Select > .Select__container::-webkit-scrollbar {
    width: 2px;
}

.Select > .Select__container::-webkit-scrollbar-track {
    background: transparent;
}

.Select > .Select__container::-webkit-scrollbar-thumb {
    border-radius: 2px;
}

.Select > .Select__container::-webkit-scrollbar-thumb:hover {
}

.Select > .Select__container > .Select__item {
    background-color: transparent;
    padding: 4px;
    margin-bottom: 4px;
    cursor: pointer;
}

.Select > .Select__container > .Select__item:last-child {
    margin-bottom: 0;
}

.Select > .Select__container > .Select__item--selected,
.Select > .Select__container > .Select__item:hover {

}


/* animation */
@-webkit-keyframes select-appearance {
    from {
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        -webkit-transform: translateY(50%);
        transform: translateY(50%);
    }
    to {
        opacity: 1;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
        -webkit-transform: translateY(0%);
        transform: translateY(0%);
    }
}

@keyframes select-appearance {
    from {
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        -webkit-transform: translateY(50%);
        transform: translateY(50%);
    }
    to {
        opacity: 1;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
        -webkit-transform: translateY(0%);
        transform: translateY(0%);
    }
}

.Select__support {
    transition: .4s;
    content: ' ';
    position: absolute;
    right: 30px;
    width: 13px;
    height: 7px;
    background-size: contain;
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='7' viewBox='0 0 14 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.92642 0.202208C1.13794 0.182707 1.34854 0.247992 1.51193 0.383708L6.99767 4.96881L12.4861 0.545329C12.5679 0.478876 12.662 0.42925 12.7631 0.399305C12.8642 0.36936 12.9702 0.359685 13.075 0.370836C13.1798 0.381987 13.2814 0.413745 13.3739 0.464284C13.4664 0.514823 13.5478 0.583309 13.6139 0.665491L13.6206 0.673095C13.6939 0.755352 13.7494 0.851853 13.7836 0.956552C13.8179 1.06125 13.8301 1.17189 13.8196 1.28154C13.8091 1.39119 13.776 1.49749 13.7225 1.59378C13.669 1.69006 13.5962 1.77421 13.5086 1.84104L7.5113 6.66886C7.36454 6.76633 7.18964 6.81267 7.01373 6.80055L6.99998 7.00002L6.99959 6.80007C6.81273 6.80044 6.6315 6.73526 6.48759 6.61607L0.487823 1.61627C0.324398 1.48043 0.221627 1.28524 0.202117 1.07364C0.182607 0.862029 0.247957 0.651338 0.38379 0.487914C0.519623 0.324489 0.714813 0.221718 0.92642 0.202208Z' fill='%231F204D'/%3E%3C/svg%3E%0A");
}

@media screen and (max-width: 1023px) {
    .Select {

    }
    .Select .Input__support {
        top: 50%;
        transform: translateY(-50%);
        background: unset;
    }
}