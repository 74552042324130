@import "./normalize.css";
@import "./colors.css";
@import "./grid.css";
@import "./fonts.css";

:root {
    color: var(--color-text-1)
}

.SectionTitle {
    font-weight: bold;
    font-size: 60px;
    line-height: 82px;
    color: var(--color-text-1);
}

.Page {
    padding-top: 168px;
}

.PageTitle {
    font-weight: bold;
    font-size: 60px;
    line-height: 82px;
    text-align: center;
    grid-column: 1/-1;
}

@media screen and (max-width: 1919px) {
    .SectionTitle {
        font-size: 48px;
        line-height: 65px;
    }
}


@media screen and (max-width: 1439px) {
    .SectionTitle {
        font-size: 36px;
        line-height: 49px;
    }
    .Page {
        padding-top: 122px;
    }

    .PageTitle {
        font-size: 48px;
        line-height: 65px;
    }
}

@media screen and (max-width: 1023px) {
    .SectionTitle {
        text-align: center;
    }
}


@media screen and (max-width: 719px) {
    .SectionTitle {
        font-size: 24px;
        line-height: 33px;
    }
    .Page {
        padding-top: 70px;
    }
    .PageTitle {
        font-size: 24px;
        line-height: 33px;
    }
}