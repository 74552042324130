@import "./font/index.css";

.Logo {
    display: grid;
    grid-template-columns: 34px auto;
    align-items: center;
    column-gap: 16px;
    margin-left: 24px;
}

.Logo__icon {
    width: 34px;
    height: 40px;
    background: center / contain no-repeat url("data:image/svg+xml, %3Csvg width='33' height='40' viewBox='0 0 33 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.4242 40C16.3079 40 16.1916 39.9827 16.0793 39.9482C6.53223 37.0138 0 28.1435 0 18.1233V5.85938C0 5.34492 0.335915 4.89062 0.828173 4.7393L16.0793 0.0517969C16.304 -0.0172656 16.5445 -0.0172656 16.7692 0.0517969L32.0203 4.7393C32.5126 4.89062 32.8485 5.34492 32.8485 5.85938V18.1233C32.8485 28.1395 26.3206 37.0126 16.7692 39.9482C16.6568 39.9827 16.5405 40 16.4242 40Z' fill='%230C60DC'/%3E%3Cpath fillRule='evenodd' clipRule='evenodd' d='M24.8801 14.6359C25.5179 15.2313 25.5517 16.2305 24.9556 16.8676L16.0914 26.3412C15.7925 26.6607 15.3743 26.842 14.9366 26.842C14.4988 26.842 14.0807 26.6607 13.7818 26.3412L8.94675 21.1738C8.35065 20.5367 8.38444 19.5375 9.02223 18.9421C9.66001 18.3466 10.6603 18.3804 11.2564 19.0175L14.9366 22.9507L22.646 14.7113C23.2421 14.0742 24.2423 14.0404 24.8801 14.6359Z' fill='%23E0EDFF'/%3E%3C/svg%3E");
}

.Logo__name {
    color: white;
    font: bold 24px "Cera Pro";
}


.Logo--black > .Logo__name {
    color: var(--color-background);
}


@media screen and (max-width: 425px) {
    .Logo {
        grid-template-columns: 20px auto;
        column-gap: 6px;
        margin-left: 16px;
    }

    .Logo__icon {
        width: 20px;
        height: 23px;
    }

    .Logo__name {
        font-size: 16px;
    }

}
