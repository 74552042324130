.CreatingDeal__condition {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 112px;
}
.CreatingDeal__condition__text {
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    padding: 0 15px;
}
.CreatingDeal__condition__img {
    position: absolute;
    height: 100%;
}
.CreatingDeal__condition__img:first-child {
    left: 0;
}
.CreatingDeal__condition__img:last-child{
    right: 0;
    transform: rotateZ(180deg);
}

@media screen and (max-width: 1919px) {
    .CreatingDeal__condition__text {
        font-size: 16px;
        line-height: 22px;
    }
    .CreatingDeal__condition span {
        top: -50px;
    }
}

@media screen and (max-width: 1439px) {

}

@media screen and (max-width: 1023px) {
    .CreatingDeal__condition {
        height: auto;
    }
}

@media screen and (max-width: 719px) {
    .CreatingDeal__condition {
        margin-bottom: 45px;
        margin-right: 0;
    }
    .CreatingDeal__condition span {
        font-size: 28px;
        line-height: 36px;
        top: -35px;
    }
    .CreatingDeal__condition__text {
        font-size: 14px;
    }
}