:root {
    --color-rgb-white: 255, 255, 255;
    --color-rgb-blue-1: 12, 96, 220;
    --color-rgb-blue-2: 61, 139, 255;
    --color-rgb-gray-1: 153, 153, 171;
    --color-rgb-gray-2: 193, 193, 202;
    --color-rgb-red: 228, 0, 55;
    --color-rgb-green: 6, 192, 103;
    --color-rgb-background: 0, 1, 73;
    --color-rgb-text-1: 31, 32, 77;
    --color-rgb-text-2: 239, 239, 244;

    --color-white: rgb(var(--color-rgb-white));
    --color-blue-1: rgb(var(--color-rgb-blue-1));
    --color-blue-2: rgb(var(--color-rgb-blue-2));
    --color-gray-1: rgb(var(--color-rgb-gray-1));
    --color-gray-2: rgb(var(--color-rgb-gray-2));
    --color-red: rgb(var(--color-rgb-red));
    --color-green: rgb(var(--color-rgb-green));
    --color-background: rgb(var(--color-rgb-background));
    --color-text-1: rgb(var(--color-rgb-text-1));
    --color-text-2: rgb(var(--color-rgb-text-2));
}