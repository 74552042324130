.ServiceRules__benefits {
    margin-bottom: 250px;
    padding-top: 40px;
    grid-row-gap: 0;
}

.ServiceRules__benefits .NoteElement {
    grid-column: 4/-4;
    margin-top: 90px;
}


@media screen and (max-width: 1919px) {
    .ServiceRules__benefits .NoteElement {
        grid-column: 3/-3;
    }
}

@media screen and (max-width: 1439px) {
    .ServiceRules__benefits {
        margin-bottom: 200px;
    }
    .ServiceRules__benefits .NoteElement {
        grid-column: 2/-2;
        margin-top: 80px;
    }
}

@media screen and (max-width: 1023px) {
    .ServiceRules__benefits {
        margin-bottom: 160px;
    }
    .ServiceRules__benefits .NoteElement {
        grid-column: 1/-1;
        margin-top: 60px;
    }
    .SolvingConflicts .Feedback {
        padding: 0 48px;
    }
}

@media screen and (max-width: 719px) {
    .ServiceRules__benefits {
        margin-bottom: 150px;
        padding-top: 25px;
    }
    .ServiceRules__benefits .NoteElement {
        margin-top: 30px;
    }
}