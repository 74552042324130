/* Features */
.FrameFeature__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0 60px;
    padding-bottom: 30px;
}

.FrameFeature__item .FrameFeature-line:first-child {
    left: 0;
}
.FrameFeature__item .FrameFeature-line:last-child{
    right: 0;
    transform: rotateZ(180deg);
}

.FrameFeature-line {
    position: absolute;
    height: 100%;
    width: 1px;
    background: var(--color-blue-1);
}
.FrameFeature-line::after {
    content:  ' ';
    position: absolute;
    top: 0;
    width: 10px;
    height: 1px;
    background: var(--color-blue-1);
}
.FrameFeature-line::before {
    content:  ' ';
    position: absolute;
    bottom: 0;
    width: 10px;
    height: 1px;
    background: var(--color-blue-1);
}

.FrameFeature__item span:nth-child(2) {
    font-weight: 600;
    font-size: 120px;
    line-height: 163px;
    color: var(--color-blue-1);
}
.FrameFeature__item span:nth-child(3) {
    font-size: 24px;
    line-height: 33px;
}

@media screen and (max-width: 1919px) {

    .FrameFeature__item span:nth-child(2) {
        font-size: 80px;
        line-height: 109px;
    }
    .FrameFeature__item span:nth-child(3) {
        font-size: 18px;
        line-height: 160%;
    }

}

@media screen and (max-width: 1439px) {
    .FrameFeature__item span:nth-child(2) {
        font-size: 60px;
        line-height: 82px;
    }
    .FrameFeature__item span:nth-child(3) {
        font-size: 16px;
        line-height: 130%;
    }
}

@media screen and (max-width: 1023px) {

}

@media screen and (max-width: 719px) {
    .FrameFeatures {
        flex-direction: column;
        padding: 0 60px;
    }
    .FrameFeature__item {
        max-width: unset;
        margin-right: 0;
        margin-bottom: 10px;
        font-size: 12px;
        line-height: 16px;
    }
    .FrameFeature__item span:nth-child(2) {
        font-size: 52px;
        line-height: 71px;
    }
    .FrameFeature__item span:nth-child(3) {
        font-size: 14px;
        line-height: 130%;
    }
}