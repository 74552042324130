.Grid {
    display: grid;
    --grid-gap: 24px;
    --grid-columns: 12;
    grid-template-columns: repeat(var(--grid-columns), 1fr);
    grid-gap: var(--grid-gap);
    padding-left: var(--grid-gap);
    padding-right: var(--grid-gap);
}

@media screen and (max-width: 1023px) {
    .Grid {
        --grid-columns: 8;
    }
}


@media screen and (max-width: 719px) {
    .Grid {
        --grid-columns: 4;
        padding-left: 16px;
        padding-right: 16px;
    }
}